import React from 'react'
import { Link } from 'react-router-dom'
import './Footer.scss';

const Footer = () => {
    return (
        <footer className='main-footer'>
            <div className="section-wrapper">
                <div className="business-info">
                    <Link className='logo-section'>
                        <img src="https://res.cloudinary.com/droimgtqi/image/upload/v1674586701/footer-logo_bfu4iw.png" alt="" />
                        República<br />Textil
                    </Link>
                    <a className='mail-contact' href="mailto:contacto@republicatextil.com">contacto@republicatextil.com</a>
                    <div className='address-info'>
                    Calle Escorza #572, Col. Americana CP 44160 Guadalajara, Jalisco.
                    </div>
                </div>
                <div className="navigation-info footer-col">
                    <h3>Navegación</h3>
                    <Link to="/servicios">Servicios</Link>
                    <Link to="/showroom">Showcase</Link>
                    <Link to="/nosotros">Nosotros</Link>
                    <Link to="/contacto">Contacto</Link>
                </div>
                {/*<div className="legal-info footer-col">
                    <h3>Legal</h3>
                    <a href="#">Terminos del Servicio</a>
                    <a href="#">Politicas de privacidad</a>
                    <a href="#">Cookies Y Protección de datos</a>
    </div>*/}
                <div className="social-info footer-col">
                    <h3>Síguenos</h3>
                    <a href="https://www.facebook.com/profile.php?id=100090527178849">Facebook</a>
                    <a href="https://www.instagram.com/republicatextilco/">Instagram</a>
                    <a href="https://www.tiktok.com/@republicatextilco">Tiktok</a>
                    <a href="https://www.linkedin.com/company/republicatextilco/">Linkedin</a>
                </div>
            </div>
            <div className="copyright-box">
                <p>Copyright © República Textil - Todos los derechos reservados</p>
            </div>
        </footer>
    )
}

export default Footer